@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
	margin: 0;
	padding: 0;
}

@media (prefers-color-scheme: dark) {
	html {
		--bg: #1b1d11;
		--text: #d7e1f5;
		--accent: #3689c9;
	}
}

@media (prefers-color-scheme: light) {
	html {
		--bg: #eceee2;
		--text: #0a1429;
		--accent: #3689c9;
	}
}

@media (prefers-color-scheme: no-preference) {
	html {
		--bg: #eceee2;
		--text: #0a1429;
		--accent: #3689c9;
	}
}

body {
	background: var(--bg);
	color: var(--text);
	font-family: "Roboto", sans-serif;
}

nav {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	font-weight: 600;
	height: 4rem;
}
nav a {
	-webkit-flex-basis: 100%;
	        flex-basis: 100%;
	color: var(--text);
	text-decoration: none;
	display: -webkit-flex;
	display: flex;
	transition: 0.5s;
}
nav a:nth-child(1) {
	font-size: 2rem;
	margin-left: min(10%, 10rem);
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}
nav a:nth-child(2) {
	font-weight: 400;
	font-size: 1.5rem;
	margin-right: min(10%, 10rem);
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}
nav a:hover {
	color: var(--accent);
	letter-spacing: 0.25rem;
}

section {
	width: min(90%, 55rem);
	margin-inline: auto;
}
section #profile {
	width: 25rem;
	height: 25rem;
	max-width: 50vw;
	max-height: 50vw;
	display: block;
}
section #logonz {
	width: 22rem;
	height: 22rem;
	max-width: 50vw;
	max-height: 50vw;
	display: block;
}

section h2 {
	font-size: 2rem;
	font-weight: 800;
}
section p {
	margin-top: min(10%, 3rem);
	font-size: 1rem;
	line-height: 1.75rem;
	margin-right: 5rem;
}
section > div {
	margin-top: min(10%, 5rem);
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
section .wai {
	margin-top: min(7%, 7rem);
}

@media (max-width: 35rem) {
	section > div {
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
	}
	section p {
		margin-right: 0;
		padding: 0.5rem;
	}

	.wai div:nth-child(1) {
		margin-top: min(15%, 3rem);
	}
	.wai div:nth-child(1) h2 {
		text-align: center;
	}
}

